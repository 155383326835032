<template>
  <div>
    <slot name="header"></slot>
    <div v-for="(sig, index) in signatures" :key="index" class="block">
      <b-notification
        :type="getSigColor(sig.indication)"
        has-icon
        :closable="false"
      >
        <!-- <p v-for="(val, name) in sig" :key="`${index}_${name}`"> -->
        <ul class="ml-5" style="list-style: disc">
          <template v-for="(val, name) in sig">
            <li v-if="val" :key="`${index}_${name}`">
              <span v-if="name !== 'certFile'">
                <b>{{ $t(`sig_verify.${name}`) }}</b
                >: {{ val }}
              </span>
              <b-button
                v-else
                @click="downloadCertificate(val)"
                size="is-small"
              >
                Scarica certificato
              </b-button>
            </li>
          </template>
        </ul>
        <!-- </p> -->
      </b-notification>
    </div>
  </div>
</template>

<script>
import FileSaver from "file-saver";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  computed: {
    // signatures() {
    //   if (this.data) {
    //     return this.data["simpleReport"]["signatureOrTimestamp"].map((sig) => {
    //       const certId = sig.certificateChain.certificate.find(
    //         (c) => c.qualifiedName === sig["signedBy"]
    //       )["id"];
    //       console.log("certId", certId);
    //       const sigDiagnostic = this.data["diagnosticData"]["signatures"].find(
    //         (s) => s.id === sig.id
    //       );
    //       console.log("sigDiagnostic", sigDiagnostic);
    //       const certDetail = sigDiagnostic["certificateChain"].find(
    //         (c) => c.certificate.id === certId
    //       )["certificate"];
    //       console.log("certDetail", certDetail);
    //       const fData = Uint8Array.from(certDetail["base64Encoded"]);
    //       const certFile = new File([fData], `${sig["signedBy"]}.cer`, {
    //         // type: "application/x-x509-ca-cert",
    //         type: "application/pkix-cert",
    //       });
    //       return {
    //         signer: sig["signedBy"],
    //         time: sig["signingTime"],
    //         format: sig["signatureFormat"],
    //         indication: sig["indication"],
    //         signatureCoverage: sig["signatureScope"][0]["scope"],
    //         valid: sigDiagnostic["structuralValidation"].valid,
    //         orgId: certDetail["organizationIdentifier"],
    //         orgName: certDetail["organizationName"],
    //         fiscalCode: certDetail["subjectSerialNumber"],
    //         certFile: certFile,
    //         // keyType: `${certDetail['publicKeyEncryptionAlgo']} ${certDetail['publicKeySize']}`,
    //       };
    //     });
    //   }
    //   return [];
    // },
    signatures() {
      if (this.data) {
        return this.data.map((sig) => {
          const fData = Uint8Array.from(
            atob(sig["certB64"])
              .split("")
              .map((char) => char.charCodeAt(0))
          );
          const certFile = new File([fData], `${sig["signer"]}.cer`, {
            // type: "application/x-x509-ca-cert",
            type: "application/pkix-cert",
          });

          // let { certB64, ...others } = sig;

          return {
            signer: sig["signer"],
            fiscalCode: sig["subjectSerialNumber"],
            orgName: sig["organizationName"],
            orgId: sig["organizationVAT"],
            certExp: sig["certExpiration"],
            time: sig["signatureTime"],
            format: sig["format"],
            indication: sig["indication"],
            subIndication: sig["subIndication"]
              ? sig["subIndication"].concat(
                  this.$t(`subIndicationEnum.${sig["subIndication"]}`)
                )
              : "",
            signatureCoverage: sig["coverage"],
            valid: sig["valid"],
            validationDate: sig["validationTime"],
            caName: sig["caName"],
            // ...others,
            certFile: certFile,
          };
        });
      }
      return [];
    },
  },
  methods: {
    downloadCertificate(certFile) {
      FileSaver.saveAs(certFile);
    },
    getSigColor(sigStatus) {
      var color = "";
      switch (sigStatus) {
        case "TOTAL_PASSED": {
          color = "is-success";
          break;
        }
        case "NO_SIGNATURE_FOUND":
        case "INDETERMINATE": {
          color = "is-warning";
          break;
        }
        case "TOTAL_FAILED": {
          color = "is-danger";
          break;
        }
      }
      return color;
    },
  },
};
</script>

<style></style>
